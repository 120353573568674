.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.3rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item-image img {
    aspect-ratio: 16/9;
    object-fit: cover;
}

.portfolio__item h3 {
    margin: 1rem 0 1.5rem;
    text-align: center;
}

.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    justify-content: center;
    align-items: center;
}

/* ==== MEDIA QUERIES ( MEDIUM DEVICES ) ==== */
@media screen and (max-width:1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}

/* ====== MEDIA QUERIES ( SMALL DEVICES ) ===== */
@media screen and (max-width:600px ) {
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }
}