nav {
    background : rgba(0, 0, 0, 0.3);
   width : max-content;
    display : block;
    padding : 0.7rem 1.7rem;
    z-index : 2;
    position : fixed;
    left : 50%;
    transform : translateX(-50%);
    bottom : 2rem;
    display : flex;
    gap : 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(20px);
}

nav a{
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.1rem;
}

nav a:hover {
    background: rgba(0, 0, 0, 0.3);
    color: rgba(255, 255, 255, 0.6);
}

nav a.active {
    background: #45a2e4;
    color: #121212;
}